import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { SessionReport } from '../models/report-model';

interface RouteService {
  generateReport: (data: { sid: string; trustId?: string }) => Promise<APIResponse<SessionReport[]>>;
}

const reportService = (httpClient: AxiosInstance): RouteService => {
  return {
    generateReport: async (data) => {
      try {
        const res: AxiosResponse<APIResponse<SessionReport[]>> = await httpClient.post('/report', data);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Failed to add logs' };
      }
    },
  };
};

export default reportService;
